<template>
  <div class="container--fluid fill-height">
    <v-container>
      <TheLayoutPageTitle title="Dúvidas mais comuns" />
      <v-row justify="center">
        <v-expansion-panels accordion>
          <v-expansion-panel
            v-for="doubt in doubts"
            :key="doubt.id"
            class="rounded-xl mb-3 pa-3"
          >
            <v-expansion-panel-header
              class="font-weight-bold blue--text"
            >
              {{ doubt.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="ml-3"
            >
              <!-- <div v-html="doubt.body" /> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TheLayoutPageTitle from '../layout/TheLayoutPageTitle.vue';
import duvidas from '../../../utils/duvidas';

export default {
  name: 'Duvidas',
  components: { TheLayoutPageTitle },
  data() {
    return {
      doubts: duvidas,
    };
  },
};
</script>

<style lang="scss" scoped>
    .v-expansion-panels--accordion > .v-expansion-panel::after {
        opacity: 0;
    }
    .v-expansion-panel .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
        color: #089BCE;
    }
</style>
