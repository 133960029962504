const duvidas = [
  {
    id: 1,
    title: 'ANÁLISE DE OBJETO PROJETADO NO ESPAÇO AÉREO',
    body: `<p class="text-justify font-weight-bold">
        1. O que é um objeto projetado no espaço aéreo (OPEA)?
    </p>
    <p class="text-justify">
        É qualquer objeto (edificação, torre, poste, antena, mastro, etc.)
        cujas dimensões se projetem no espaço aéreo e possam interferir na
        operacionalidade de um aeródromo, auxílio à navegação aérea ou
        procedimento de navegação aérea.
    </p>
    <p class="text-justify font-weight-bold">
        2.  Por que o projeto de um OPEA deve ser submetido à análise do
        Comando da Aeronáutica (COMAER)?
    </p>
    <p class="text-justify">
        Para que o COMAER possa avaliar se o objeto causa algum efeito adverso
        à segurança e à regularidade das operações aéreas. É um passo
        preliminar à apresentação do projeto para aprovação da
        Prefeitura do Município.
    </p>
    <p class="text-justify font-weight-bold">
        3.    Em quais circunstâncias eu devo submeter minha implantação
        à análise do COMAER?
    </p>
    <p class="text-justify">
        Os critérios para solicitação de análise de objeto projetado no
        espaço aéreo estão contidos no Capítulo VII da
        <a href="http://servicos.decea.gov.br/static/aga/arquivos/74c4d3b8-a39e-4ffb-981bcc61fff0e7d9.pdf">
        Portaria nº 957/GC3, de 9 de julho de 2015.
        </a>
    </p>
    <p class="text-justify font-weight-bold">
        4.    Quem deve submeter um processo de análise de objeto projetado
        no espaço aéreo ao COMAER?
    </p>
    <p class="text-justify">
        Qualquer pessoa física ou jurídica com interesse na implantação.
    </p>
    <p class="text-justify font-weight-bold">
        5.    Qual documentação eu devo encaminhar ao COMAER para solicitar
        análise de um objeto projetado no espaço aéreo?
    </p>
    <p class="text-justify">
        O interessado deverá apresentar ao Órgão Regional do DECEA os
        documentos listados no ANEXO C1, da
        <a href="https://publicacoes.decea.gov.br/?i=publicacao&id=4879">
        ICA 11-3/2018.
        </a>
    </p>
    <p class="text-justify font-weight-bold">
        6.    Quais critérios são observados pelo COMAER na análise de
        um objeto projetado no espaço aéreo?
    </p>
    <p class="text-justify">
        As alturas máximas previstas:
    </p>
    <ul>
        <li>
        Nos Planos de Zona de Proteção dos Aeródromos (PBZPA),
        Helipontos (PBZPH) e Auxílios à Navegação Aérea (PZPANA) envolvidos;
        </li>
        <li>
        Os critérios previstos no Plano Básico do Gerenciamento do
        Risco de Fauna (PBGRF); e
        </li>
        <li>
        Os critérios relacionados com implantações de natureza perigosa.
        </li>
    </ul><br>
    <p class="text-justify font-weight-bold">
        7.    Existe uma distância mínima de um aeródromo para a implantação
        de atividades que possam atrair aves (recicladoras, vazadouros de
        resíduos sólidos, áreas de descarga de esgoto, entre outros)?
    </p>
    <p class="text-justify">
        A distância mínima será definida de acordo com o tipo de atividade
        e as Licenças Prévia e de Instalação (LP e LI), conforme Tabela A
        da PCA 3-3 Plano Básico de Gerenciamento do Risco de Fauna.
        O Plano Básico de Gerenciamento do Risco de Fauna (PCA 3-3) está
        disponível em
        <a href="http://www2.fab.mil.br/cenipa/index.php/legislacao/pca-plano-do-comando-da-aeronautica">
        PCA 3-3/2018
        </a>.
        A Ficha para o reporte de eventos com fauna está disponível em
        <a href="http://sistema.cenipa.aer.mil.br/cenipa/sigra/perigoAviarioExt">
        Reporte (FC15)
        </a>.
    </p>`,
  },
  {
    id: 2,
    title: 'ANÁLISE, OPEA, ZPA',
    body: `<p class="text-justify font-weight-bold">
        1. Como é feita a análise de OPEA?
    </p>
    <p class="text-justify">
        O obstáculo será analisado em função de todas as ZPA em que ele possa influenciar,
        conforme a
        <a href="http://servicos.decea.gov.br/static/aga/arquivos/74c4d3b8-a39e-4ffb-981bcc61fff0e7d9.pdf">
        Portaria nº 957/GC3, de 9 de julho de 2015.
        </a>
    </p>
    <p class="text-justify">
        Veja:
    </p>
    <p class="text-justify">
        Art. 85. O efeito adverso OPEA avalia a possibilidade de interferência de um
        objeto:
    </p>
    <p class="text-justify">
        I - no serviço de controle de aeródromo;
    </p>
    <p class="text-justify">
        II - nas características físicas de aeródromo;
    </p>
    <p class="text-justify">
        III - nos auxílios à navegação aérea;
    </p>
    <p class="text-justify">
        IV - nas operações aéreas em condições normais;
    </p>
    <p class="text-justify">
        V - nas operações aéreas em contingência; e
    </p>
    <p class="text-justify">
        VI - na segurança de voo. (CENIPA)
    </p>`,
  },
  {
    id: 3,
    title: 'ANDAMENTO DE PROCESSO',
    body: `<p class="text-justify font-weight-bold">
        1. Consulta
    </p>
    <p class="text-justify">
        A consulta de andamento de processos é feita pelo canal de atendimento SAC, e
        são necessárias as seguintes informações:
    </p>
    <ul>
        <li>
        Tipo de processo conforme ICA 11-3;
        </li>
        <li>
        Número do processo (NUP);
        </li>
        <li>
        Tipo de processo conforme ICA 11-3;
        </li>
        <li>
        Tipo de processo conforme ICA 11-3;
        </li>
        <li>
        Tipo de processo conforme ICA 11-3;
        </li>
    </ul>
    <br>
    <p class="text-justify">
        A <a href="https://publicacoes.decea.gov.br/?i=publicacao&id=4879">ICA 11-3/2018</a> estabelece prazo de 60 dias para análise
        de pedido inicial (do recebimento da documentação física).
        Antes do término desse prazo, solicitamos que o usuário
        aguarde a resposta do Órgão Regional.
    </p>`,
  },
  {
    id: 4,
    title: 'EXPLORAÇÃO DE AERÓDROMO CIVIL PÚBLICO',
    body: `<p class="text-justify font-weight-bold">
        1. O que é exploração de aeródromo civil público?
    </p>
    <p class="text-justify">
        Processo por meio do qual o COMAER analisa, quanto aos temas
        de sua competência, as solicitações de instrumento de outorga,
        coordenado pela SAC-MTPA, para exploração de aeródromos civis públicos.
    </p>
    <p class="text-justify font-weight-bold">
        2. Por que a solicitação de instrumento de outorga deve ser
        submetida à análise do Comando da Aeronáutica (COMAER)?
    </p>
    <p class="text-justify">
        Porque é exigida como etapa constituinte do processo de
        obtenção do instrumento de outorga para exploração de aeródromos
        civis públicos, nos termos do
        <a href="http://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2011/Decreto/D7624.htm">
        Decreto nº 7.624, de 22 de novembro de 2011
        </a>, e do
        <a href="http://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2012/Decreto/D7871.htm">
        Decreto nº 7.871, de 21 de dezembro de 2012
        </a>.
    </p>
    <p class="text-justify font-weight-bold">
        3. A quem deve ser apresentado o processo de exploração
        de aeródromo civil público?
    </p>
    <p class="text-justify">
        O processo de análise de viabilidade de exploração de
        aeródromo civil público deve ser apresentado pelo interessado à SAC-MTPA.
    </p>
    <p class="text-justify font-weight-bold">
        4. A deliberação favorável do COMAER nos processos de exploração
        de aeródromo civil público supre o pedido de inscrição no cadastro de aeródromo?
    </p>
    <p class="text-justify">
        Não. A deliberação favorável do COMAER não supre a necessidade
        de posterior apresentação do pedido de inscrição no cadastro
        de aeródromos da ANAC, conforme estabelecido no Capítulo 6 da
        <a href="https://publicacoes.decea.gov.br/?i=publicacao&id=4879">
        ICA 11-3/2018
        </a>
    </p>
    <p class="text-justify font-weight-bold">
        5. Qual a validade da deliberação do COMAER nos processos
        de exploração de aeródromo civil público?
    </p>
    <p class="text-justify">
        A deliberação favorável do COMAER para um processo de exploração
        de aeródromo civil público terá validade de cinco anos apara
        efeito de apresentação do processo de inscrição ou alteração
        no cadastro, conforme o caso, por parte do interessado.
    </p>
    <p class="text-justify font-weight-bold">
        6. Qual documentação o interessado deve encaminhar para análise
        do COMAER nos processos de exploração de aeródromo civil público?
    </p>
    <p class="text-justify">
        O interessado deverá entregar os documentos listados no ANEXO B1, da
        <a href="https://publicacoes.decea.gov.br/?i=publicacao&id=4879">
        ICA 11-3/2018
        </a>, assinalados na coluna “Exploração AD CIV PUB”.
    </p>
    <p class="text-justify font-weight-bold">
        7. Quais critérios são observados pelo COMAER durante a análise
        dos processos de exploração de aeródromo civil público?
    </p>
    <p class="text-justify">
        Aqueles previstos na
        <a href="http://publicacoes.decea.gov.br/?i=publicacao&id=4176">
        ICA 63-19/2015
        </a>.
    </p>`,
  },
  {
    id: 5,
    title: 'INSCRIÇÃO E ALTERAÇÃO NO CADASTRO',
    body: ` <p class="text-justify font-weight-bold">
        1. O que é um projeto de inscrição ou alteração de aeródromo?
    </p>
    <p class="text-justify">
        Documento elaborado por um Responsável Técnico habilitado e
        apresentado pelo operador do aeródromo, que estabelece ou
        altera a configuração da infraestrutura aeroportuária incluindo
        as características físicas e/ou operacionais do aeródromo.
    </p>
    <p class="text-justify font-weight-bold">
        2. Por que o um projeto de inscrição ou alteração de aeródromo deve ser
        submetido à análise do Comando da Aeronáutica (COMAER)?
    </p>
    <p class="text-justify">
        Para que o COMAER possa determinar possíveis efeitos adversos à segurança
        e à regularidade das operações aéreas, analisar possíveis interferências com
        a circulação aérea de outros aeródromos e avaliar a existência de implantação de
        natureza perigosa nas superfícies de aproximação, transição e decolagem. A análise
        do COMAER é uma exigência da Agência Nacional de Aviação Civil (ANAC) estabelecida na
        <a href="http://www.anac.gov.br/assuntos/legislacao/legislacao-1/resolucoes/resolucoes-2010/resolucao-no-158-de-13-07-2010/@@display-file/arquivo_norma/RA2010-0158.pdf">
        Resolução nº 158, de 13 de julho de 2010
        </a>.
    </p>
    <p class="text-justify font-weight-bold">
        3. Como a AAL pode tomar conhecimento se o seu aeródromo é de interesse militar?
    </p>
    <p class="text-justify">
        Por meio de consulta ao Órgão Regional do DECEA (CINDACTA/SRPV-SP) com jurisdição
        na área na qual está localizado o aeródromo.
        <a href="http://servicos.decea.gov.br/aga/?i=downloads">
        (Mapa de jurisdição dos Órgãos Regionais do DECEA)
        </a>
    </p>
    <p class="text-justify font-weight-bold">
        4. Quais AAL devem submeter um projeto de inscrição ou alteração de aeródromo à
        aprovação do COMAER?
    </p>
    <p class="text-justify">
        As AAL que administram ou administrarão um aeródromo público ou privado.
    </p>
    <p class="text-justify font-weight-bold">
        5. Qual documentação a AAL deve encaminhar ao COMAER para solicitar aprovação
        do projeto de inscrição ou alteração de aeródromo?
    </p>
    <p class="text-justify">
        A AAL deverá entregar os documentos listados no ANEXO B1, da
        <a href="https://publicacoes.decea.gov.br/?i=publicacao&id=4176">ICA 11-3/2018</a>,
        assinalados na coluna “inscrição ou alteração” ou na coluna “renovação”,
        conforme o caso.
    </p>
    <p class="text-justify font-weight-bold">
        6. Quais critérios são observados pelo COMAER durante a análise de um projeto de
        inscrição ou alteração de aeródromo?
    </p>
    <p class="text-justify">
        Aqueles previstos na <a href="http://publicacoes.decea.gov.br/?i=publicacao&id=4176">ICA 63-19/2015</a>.
    </p>`,
  },
  {
    id: 6,
    title: 'OPEA, SINALIZAÇÃO',
    body: `<p class="text-justify font-weight-bold">
        1. Quando um OPEA deve ser sinalizado?
    </p>
    <p class="text-justify">
        Segundo a legislação vigente que trata sobre o assunto, diz que um
        novo objeto ou objeto existente deve ser sinalizado e iluminado,
        nos seguintes casos:
    </p>
    <p class="text-justify">
        I - quando se tratar de torres, mastros, postes, linhas elétricas
        elevadas, cabos suspensos ou outros objetos cuja configuração seja
        pouco visível a distância que estejam localizados dentro dos limites
        laterais da superfície de transição ou dentro dos 3.000 metros das
        superfícies de aproximação e decolagem, ainda que não ultrapassem
        os limites verticais dessas superfícies; (o obstáculo em questão
        está fora destas áreas);
    </p>
    <p class="text-justify">
        II - quando se tratar de linhas elétricas elevadas, cabos suspensos ou
        outros objetos de configuração semelhante, que atravessem rios, hidrovias,
        vales ou estradas; (não se trata deste tipo de obstáculo); e
    </p>
    <p class="text-justify">
        III - quando se tratar de objetos que se elevem a 150 metros ou mais
        de altura. (Este é o enquadramento do obstáculo em questão).
    </p>
    <p class="text-justify red--text">
        <strong>
        Denúncias deverão ser levadas à Prefeitura para que a mesma verifique
        se a altura da antena é maior que 150 metros e, assim, exigir a iluminação.
        </strong>
    </p>`,
  },
  {
    id: 7,
    title: 'PLANO DIRETOR AEROPORTUÁRIO',
    body: `<p class="text-justify font-weight-bold">
        1. O que é um Plano Diretor Aeroportuário?
    </p>
    <p class="text-justify">
        Documento elaborado pelo operador do aeródromo, que estabelece o planejamento
        para a expansão da infraestrutura aeroportuária em consonância com a
        regulamentação de segurança operacional expedida pela ANAC. Este documento,
        para os fins da
        <a href="http://publicacoes.decea.gov.br/?i=publicacao&id=4177">
        ICA 11-3/2015
        </a>, deverá ser elaborado por responsáveis técnicos devidamente qualificados.
    </p>
    <p class="text-justify font-weight-bold">
        2. Por que o um projeto um Plano Diretor Aeroportuário deve ser submetido à
        análise do Comando da Aeronáutica (COMAER)?
    </p>
    <p class="text-justify">
        Para que o COMAER possa determinar possíveis efeitos adversos à segurança e à
        regularidade das operações aéreas, analisar possíveis interferências com a
        circulação aérea de outros aeródromos e avaliar a existência de implantação
        de natureza perigosa nas superfícies de aproximação, transição e decolagem.
        A análise do COMAER é uma exigência da Agência Nacional de Aviação Civil (ANAC)
        estabelecida na
        <a href="http://www.anac.gov.br/assuntos/legislacao/legislacao-1/resolucoes/resolucoes-2010/resolucao-no-153-de-18-06-2010/@@display-file/arquivo_norma/A2010-0153.pdf">
        Resolução nº 153, de 18 de junho de 2010
        </a>.
    </p>
    <p class="text-justify font-weight-bold">
        3. Como a AAL pode tomar conhecimento se o seu aeródromo é de interesse militar?
    </p>
    <p class="text-justify">
        Por meio do Órgão Regional do DECEA (CINDACTA/SRPV-SP) com jurisdição na área na
        qual está localizado o aeródromo.
        <a href="http://servicos.decea.gov.br/aga/?i=downloads">
        (Mapa de jurisdição dos Órgãos Regionais do DECEA)
        </a>
    </p>
    <p class="text-justify font-weight-bold">
        4. Quais AAL devem submeter um Plano Diretor Aeroportuário à aprovação do COMAER?
    </p>
    <p class="text-justify">
        A obrigatoriedade restringe-se aos casos das AAL que administram aeródromos que
        tenham que submeter o seu planejamento à aprovação da ANAC, conforme estabelecido
        na
        <a href="http://www.anac.gov.br/assuntos/legislacao/legislacao-1/resolucoes/resolucoes-2010/resolucao-no-153-de-18-06-2010/@@display-file/arquivo_norma/A2010-0153.pdf">
        Resolução nº 153, de 18 de junho de 2010
        </a>.
        A AAL de um aeródromo que não faça parte do escopo de aprovação da Agência poderá,
        a seu critério, submeter um Plano Diretor Aeroportuário à análise do COMAER.
    </p>
    <p class="text-justify font-weight-bold">
        5. Qual documentação a AAL deve encaminhar ao COMAER para solicitar aprovação do
        plano diretor aeroportuário?
    </p>
    <p class="text-justify">
        A AAL deverá entregar os documentos listados no ANEXO B1, da
        <a href="https://publicacoes.decea.gov.br/?i=publicacao&id=4879">
        ICA 11-3/2018
        </a>, assinalados na coluna referente a PDIR.
    </p>
    <p class="text-justify font-weight-bold">
        6. Quais critérios são observados pelo COMAER durante a análise de um Plano
        Diretor Aeroportuário?
    </p>
    <p class="text-justify">
        Aqueles previstos na
        <a href="http://publicacoes.decea.gov.br/?i=publicacao&id=4176">
        ICA 63-19/2015
        </a>
    </p>`,
  },
  {
    id: 8,
    title: 'PRAZOS',
    body: `<p class="text-justify font-weight-bold">
        1. Prazos para o andamento de Processos
    </p>
    <p class="text-justify">
        O prazo para a realização das atividades sob a responsabilidade
        de um determinado setor ou organização subordinada ao COMAER
        iniciarão a partir do momento do recebimento da documentação
        por meio físico ou eletrônico. Todos os prazos estão estabelecidos
        na
        <a href="https://publicacoes.decea.gov.br/?i=publicacao&id=4879">
        ICA 11-3/2018
        </a>
        no <strong>Item 4 - PRAZOS</strong>.
    </p>`,
  },
  {
    id: 9,
    title: 'PROCESSO E PRÉ-ANÁLISE',
    body: `<p class="text-justify font-weight-bold">
        1. O que é Pré-análise?
    </p>
    <p class="text-justify">
        A pré-análise consiste em uma verificação baseada nos critérios
        para solicitação de análise de objeto projetado no espaço aéreo
        que não são contempladas no Capítulo VII da Portaria nº 957/GC3,
        de 09 de julho de 2015. Com a facilidade da pré-análise,
        basta o solicitante submeter as informações básicas de
        coordenadas, altitude da base, altura do objeto (que vai permitir
        que se chegue a altitude do topo, posicionado em relação a um
        plano básico de um aeródromo existente no entorno do projeto),
        para saber se ele estaria apto ou não a construir naquela área.
        Com isso, a ferramenta evita a abertura de processos desnecessários,
        pois gera um documento de inexigibilidade, caso o resultado da
        análise seja positivo. Em caso negativo, o requerente receberá
        a informação de que precisará abrir um processo através do Sistema
        de Gerenciamento de Processos da Área AGA (SYSAGA). A partir de
        01/10/2018 a pré-análise passou a ser uma etapa de abertura de Processo no SYSAGA.
    </p>
    <p class="text-justify font-weight-bold">
        2. Como abrir uma PRÉ-ANÁLISE e processos de OPEA e AERÓDROMO?
    </p>
    <p class="text-justify">
        1. ABERTURA DE PROCESSO DE
        <router-link to="/pre-analise">
        PRÉ-ANÁLISE
        </router-link>
    </p>
    <p class="text-justify">
        2. ABERTURA DE PROCESSO
        <a href="http://servicos.decea.gov.br/aga/index.cfm?i=processos&tipo=opea">
        OPEA
        </a>
    </p>
    <p class="text-justify">
        3. ABERTURA DE PROCESSO
        <a href="http://servicos.decea.gov.br/aga/index.cfm?i=processos&tipo=aero">
        AERÓDROMO
        </a>
    </p>`,
  },
  {
    id: 10,
    title: 'PROFISSIONAL HABILITADO',
    body: `<p class="text-justify font-weight-bold">
        1. Profissional Habilitado
    </p>
    <p class="text-justify">
        Os profissionais capacitados para assumir a responsabilidade
        técnica pelas informações constantes do processo de OPEA,
        incluindo informações topográficas (altitudes e coordenadas
        geográficas), são os listados na Tabela 3-1 da
        <a href="https://publicacoes.decea.gov.br/?i=publicacao&id=4879">
        ICA 11-3/2018
        </a>,
        em conformidade com a Decisão nº PL-1184/2015, do CONFEA.
        Para a inclusão de possíveis responsáveis técnicos distintos
        dos elencados na Tabela 3-1, será necessário formalizar um
        pedido formal mediante o envio de ofício ao DECEA, solicitando
        a inclusão na supracitada lista, inserindo os argumentos
        técnicos e legais pertinentes, para que este Departamento possa
        consultar novamente o CONFEA, buscando uma revisão da lista e da ICA 11-3.
    </p>`,
  },
  {
    id: 11,
    title: 'SINALIZAÇÃO E ILUMINAÇÃO DE OBJETOS',
    body: `<p class="text-justify font-weight-bold">
        1. Turbinas Eólicas
    </p>
    <ul>
        <li>
        As turbinas eólicas serão sinalizadas e iluminadas quando
        classificadas como obstáculos.
        </li>
        <li>
        A sinalização de uma turbina eólica deve ser realizada por meio
        da pintura, na cor branca, das pás do rotor, nacele e dois terços
        superiores do mastro e por meio da pintura, na cor laranja
        (ou vermelha), do primeiro terço do mastro. Para mais
        esclarecimentos ver Art. 76 da
        <a href="http://servicos.decea.gov.br/static/aga/arquivos/74c4d3b8-a39e-4ffb-981bcc61fff0e7d9.pdf">
            Portaria nº 957/GC3, de 9 de julho de 2015.
        </a>
        </li>
    </ul>`,
  },
];

export default duvidas;
